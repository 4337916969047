import classNames from 'classnames';
import React, { ReactNode } from 'react';
import classes from './Dropdown.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
}

const Dropdown = ({ children, className, ...divProps }: Props) => (
  <div className={classNames(className, classes.Dropdown)} {...divProps}>
    {children}
  </div>
);

export default Dropdown;
