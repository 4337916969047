import React from 'react';
import { MultiValueRemoveProps, components } from 'react-select';
import CrossSvg from '@icons/cross.svg';
import classes from './CustomMultiValueRemove.module.scss';

const CustomMultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <div data-cy="next-select-multi-value-remove" className={classes.CustomMultiValueRemove}>
      <CrossSvg />
    </div>
  </components.MultiValueRemove>
);

export default CustomMultiValueRemove;
