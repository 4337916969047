import React from 'react';
import { IndicatorsContainerProps, components } from 'react-select';
import ChevronDownSvg from '@icons/chevron-down.svg';
import classes from './CustomIndicatorsContainer.module.scss';

const CustomIndicatorsContainer = (props: IndicatorsContainerProps) => {
  return (
    <components.IndicatorsContainer className={classes.CustomIndicatorsContainer} {...props}>
      <ChevronDownSvg />
    </components.IndicatorsContainer>
  );
};

export default CustomIndicatorsContainer;
