import ChevronDownIcon from '@lmc-eu/spirit-icons/svg/chevron-down.svg';
import ChevronUpIcon from '@lmc-eu/spirit-icons/svg/chevron-up.svg';
import classNames from 'classnames';
import React from 'react';
import { ControlProps, components } from 'react-select';
import classes from './CustomControl.module.scss';

const CustomControlB2bAdmin = ({ children, className, ...props }: ControlProps) => {
  return (
    <div data-cy="next-select-control">
      <components.Control
        className={classNames(
          classes.CustomControlB2bAdmin,
          props.menuIsOpen ? classes.CustomControlFocused : '',
          className,
        )}
        {...props}
      >
        {children}
        <div className={classes.CustomControlB2bAdmin__icon}>
          {props.menuIsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </components.Control>
    </div>
  );
};

export default CustomControlB2bAdmin;
