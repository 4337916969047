import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './global';

export const GALLERY_CONTENT_ITEM_FRAGMENT = gql`
  fragment GalleryContentItemFragment on ProfileVisualContentItemData {
    title
    description
    type
    image {
      ...ImageFragment
    }
    linkToYoutubeVideo
  }
  ${IMAGE_FRAGMENT}
`;

export const PROFILE_PAID_FEATURES_FRAGMENT = gql`
  fragment ProfilePaidFeaturesFragment on ProfilePaidFeatures {
    hasPhoto
    hasMotto
    hasLogo
    hasExternalProfileUrl
    hasAggregatedJobDescriptions
    isAdvertisementHidden
    hasProfileVisualContentGallery
    hasAboutUs
    hasPinnedComment
    hasQuestionnaireReplyEditor
    hasEmployerUnit
    hasEmployerUnitOnProfile
    hasSingleCoverPhoto
  }
`;

export const ABSTRACT_PROFILE_FRAGMENT = gql`
  fragment AbstractProfileFragment on AbstractProfile {
    id
    urlIdentifier
    g2IdsAsIntegers
    name
    expandedName
    webalizedName
    externalProfileUrl
    fieldOfBusinessTitle
    isProfileFeedbackBarInteractionEnabled
    numberOfEmployees
    aboutUs
    employerUnitType
    logo {
      ...ImageFragment
    }
    visualContentGalleryPublished
  }
  ${IMAGE_FRAGMENT}
`;

export const PROFILE_OVERVIEW_FRAGMENT = gql`
  fragment ProfileOverviewFragment on ProfileOverview {
    isInternalSurvey
    countOfReviews
    countOfComments
    countOfJobOpportunities
    employerSatisfaction
    colleaguesSatisfaction
    bossSatisfaction
    mottoTags
    countOfHeaderColumns
    countOfVisibleVisualContentsInGallery
    topCommentData {
      employerUnitName
      isCurrentlyEmployed
      howLongWithCompany
      specialization
      leftCompanyAgo
      unconfirmed
      howLongAgoAdded
      negativeFeedbackAnswer
      positiveFeedbackAnswer
      monthAndYearOfFinishFormatted
      isPinned
      questionnaireId
    }
    top3SuitableFor {
      numberOfEmployeesRecommending
      multiChoiceOption {
        questionnaireLabel
      }
    }
    profile {
      ...AbstractProfileFragment
    }
    googleMapsUrl
    googleSearchUrl
    headOfficeAddress
    photo {
      ...ImageFragment
    }
    logo {
      ...ImageFragment
    }
    isCoverPhotoVisible
    isLogoVisible
    isMottoVisible
    latestActiveContract {
      type
    }
    visualContentGalleryVisible
    isAdvertisementHidden
    hasFairEmployerBadge
    profileG2IdsAsIntegers
    isExternalProfileUrlVisible
  }
  ${ABSTRACT_PROFILE_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

export const PROFILE_CARD_DATA_FRAGMENT = gql`
  fragment ProfileCardDataFragment on ProfileCardData {
    profile {
      ...AbstractProfileFragment
    }
    name
    profileDetailUrl
    logo {
      ...ImageFragment
    }
    isLogoVisible
    photo {
      ...ImageFragment
    }
    isPhotoVisible
    countOfReviews
    countOfJobDescriptions
    top3SuitableForLabels
    employerSatisfaction
    colleaguesSatisfaction
    bossSatisfaction
    headOfficeAddressCity
    fieldOfBusinessTitle
    numberOfMatchedTags
    totalMatchingTags
    matchLevel
    isUserSubscribedToProfileJobDescriptions
    latestActiveContract {
      type
    }
  }
  ${ABSTRACT_PROFILE_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;
