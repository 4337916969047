import type { ParsedUrlQuery } from 'querystring';
import { gql } from '@apollo/client';
import { nonEmptyStringOrNull } from '@libs/stringUtils';
import { PAGINATOR_FRAGMENT } from 'graphql/fragments/global';
import { JOB_DESCRIPTION_LINK_WITH_PROFILE_INFO_FRAGMENT } from 'graphql/fragments/jobDescriptionList';
import {
  GALLERY_CONTENT_ITEM_FRAGMENT,
  PROFILE_CARD_DATA_FRAGMENT,
  PROFILE_OVERVIEW_FRAGMENT,
  PROFILE_PAID_FEATURES_FRAGMENT,
} from 'graphql/fragments/profile';
import { REVIEW_DATA_FRAGMENT } from '../fragments/reviewData';
import {
  EmployeeStatusChoices,
  ProfileTabName,
  QuestionnaireVisibility,
  TextAnswerOrderBy,
} from '../generated/types/graphqlTypes';

const createInternalSurveyReportQueryVariables = (query: ParsedUrlQuery) => {
  return {
    internalSurveyReportAccessToken: nonEmptyStringOrNull(query.accessToken),
    internalSurveyUrlHash: nonEmptyStringOrNull(query.urlHash),
  };
};

const createQuestionnaireFilterInput = (query: ParsedUrlQuery) => {
  return {
    employerUnitId: Number(query.employerUnit) || undefined,
    employeeStatus: (query.employeeStatus as EmployeeStatusChoices) || undefined,
    fieldOfWorkId: Number(query.fieldOfWork) || undefined,
  };
};

export const PROFILE_PAID_FEATURES = gql`
  query ProfilePaidFeatures($profileId: Int!) {
    profilePaidFeatures(profileId: $profileId) {
      ...ProfilePaidFeaturesFragment
    }
  }
  ${PROFILE_PAID_FEATURES_FRAGMENT}
`;

export const PROFILE_OVERVIEW = gql`
  query ProfileOverview($profileId: Int!, $internalSurveyReportAccessToken: String, $internalSurveyUrlHash: String) {
    profileOverview(
      profileId: $profileId
      internalSurveyReportAccessToken: $internalSurveyReportAccessToken
      internalSurveyUrlHash: $internalSurveyUrlHash
    ) {
      ...ProfileOverviewFragment
    }
  }
  ${PROFILE_OVERVIEW_FRAGMENT}
`;

export const createProfileOverviewQueryVariables = (profileId: number, query: ParsedUrlQuery) => {
  return {
    profileId,
    ...createInternalSurveyReportQueryVariables(query),
  };
};

export const PROFILE_TEXT_DATA = gql`
  query ProfileTextData(
    $profileId: Int!
    $questionnaireVisibility: QuestionnaireVisibility
    $userIdentifier: String
    $page: Int
    $questionnaireFilter: QuestionnaireFilterInput
    $internalSurveyReportAccessToken: String
    $internalSurveyUrlHash: String
    $textAnswerOrderBy: TextAnswerOrderBy
    $separateUnconfirmedReviews: Boolean
  ) {
    profileTextData(
      profileId: $profileId
      questionnaireVisibility: $questionnaireVisibility
      userIdentifier: $userIdentifier
      page: $page
      questionnaireFilter: $questionnaireFilter
      internalSurveyReportAccessToken: $internalSurveyReportAccessToken
      internalSurveyUrlHash: $internalSurveyUrlHash
      textAnswerOrderBy: $textAnswerOrderBy
      separateUnconfirmedReviews: $separateUnconfirmedReviews
    ) {
      answers {
        currentPageItems {
          ...ReviewDataFragment
        }
        paginator {
          ...PaginatorFragment
        }
      }
      questions
    }
  }
  ${PAGINATOR_FRAGMENT}
  ${REVIEW_DATA_FRAGMENT}
`;

export const createProfileTextDataQueryVariables = (
  profileId: number,
  query: ParsedUrlQuery,
  questionnaireVisibility: QuestionnaireVisibility,
  page: number,
  textAnswerOrderBy: TextAnswerOrderBy,
) => {
  return {
    profileId,
    questionnaireFilter: createQuestionnaireFilterInput(query),
    ...createInternalSurveyReportQueryVariables(query),
    questionnaireVisibility,
    page,
    textAnswerOrderBy,
    separateUnconfirmedReviews: query.fromMailNotification !== '1',
  };
};

export const PROFILE_CHOICES_FOR_PROFILE_FILTER_WITH_COUNTS = gql`
  query ChoicesForProfileFilterWithCounts(
    $profileId: Int!
    $questionnaireVisibility: QuestionnaireVisibility
    $questionnaireFilter: QuestionnaireFilterInput
    $tabName: ProfileTabName!
    $internalSurveyReportAccessToken: String
    $internalSurveyUrlHash: String
  ) {
    choicesForProfileFilterWithCounts(
      profileId: $profileId
      questionnaireVisibility: $questionnaireVisibility
      questionnaireFilter: $questionnaireFilter
      tabName: $tabName
      internalSurveyReportAccessToken: $internalSurveyReportAccessToken
      internalSurveyUrlHash: $internalSurveyUrlHash
    ) {
      choicesForEmployerUnit {
        value
        label
        counts
      }
      choicesForFieldOfWork {
        value
        label
        counts
      }
      choicesForEmployeeStatus {
        value
        label
        counts
      }
    }
  }
`;

export const createChoicesForProfileFilterWithCountsQueryVariables = (
  profileId: number,
  tabName: ProfileTabName,
  query: ParsedUrlQuery,
) => {
  return {
    profileId,
    tabName,
    questionnaireFilter: createQuestionnaireFilterInput(query),
    ...createInternalSurveyReportQueryVariables(query),
  };
};

export const PROFILE_VISUAL_CONTENT_GALLERY_DATA = gql`
  query ProfileVisualContentGalleryData($profileId: Int!) {
    profileVisualContentGalleryData(profileId: $profileId) {
      heroVisualContents {
        ...GalleryContentItemFragment
      }
      summaryVisualContents {
        ...GalleryContentItemFragment
      }
      cultureVisualContents {
        ...GalleryContentItemFragment
      }
      galleryVisualContents {
        ...GalleryContentItemFragment
      }
      commentVisualContentsForReactComponent {
        ...GalleryContentItemFragment
      }
      heroVisualContentsShowMorePlaceholder {
        ...GalleryContentItemFragment
      }
      summaryVisualContentsShowMorePlaceholder {
        ...GalleryContentItemFragment
      }
      commentVisualContentsShowMorePlaceholderForReactComponent {
        ...GalleryContentItemFragment
      }
      cultureVisualContentsShowMorePlaceholder {
        ...GalleryContentItemFragment
      }
    }
  }
  ${GALLERY_CONTENT_ITEM_FRAGMENT}
`;

export const PROFILE_COUNT_OF_UNCONFIRMED_REVIEWS_WITH_COMMENTS = gql`
  query ProfileCountOfUnconfirmedReviewsWithComments(
    $profileId: Int!
    $questionnaireFilter: QuestionnaireFilterInput
    $internalSurveyReportAccessToken: String
    $internalSurveyUrlHash: String
  ) {
    countOfUnconfirmedReviewsWithCommentForProfile(
      profileId: $profileId
      questionnaireFilter: $questionnaireFilter
      internalSurveyReportAccessToken: $internalSurveyReportAccessToken
      internalSurveyUrlHash: $internalSurveyUrlHash
    )
  }
`;

export const createProfileCountOfUnconfirmedReviewsWithCommentsQueryVariables = (
  profileId: number,
  query: ParsedUrlQuery,
) => {
  return {
    profileId,
    questionnaireFilter: createQuestionnaireFilterInput(query),
    ...createInternalSurveyReportQueryVariables(query),
  };
};

export const PROFILE_PROMOTED_PROFILE_CARDS = gql`
  query ProfilePromotedProfileCards {
    profilePromotedProfileCards {
      ...ProfileCardDataFragment
      top3JobDescriptions {
        ...JobDescriptionLinkWithProfileInfoFragment
      }
    }
  }
  ${PROFILE_CARD_DATA_FRAGMENT}
  ${JOB_DESCRIPTION_LINK_WITH_PROFILE_INFO_FRAGMENT}
`;

export const PROFILE_COMPANY_CULTURE_DATA = gql`
  query ProfileCompanyCultureData(
    $profileId: Int!
    $questionnaireVisibility: QuestionnaireVisibility
    $questionnaireFilter: QuestionnaireFilterInput
    $internalSurveyReportAccessToken: String
    $internalSurveyUrlHash: String
  ) {
    profileCompanyCultureData(
      profileId: $profileId
      questionnaireVisibility: $questionnaireVisibility
      questionnaireFilter: $questionnaireFilter
      internalSurveyReportAccessToken: $internalSurveyReportAccessToken
      internalSurveyUrlHash: $internalSurveyUrlHash
    ) {
      satisfactionValuesForEmployerCategory {
        questionLabel
        value
      }
      satisfactionValuesForBossCategory {
        questionLabel
        value
      }
      satisfactionValuesForColleaguesCategory {
        questionLabel
        value
      }
    }
    payHappinessData(
      profileId: $profileId
      questionnaireVisibility: $questionnaireVisibility
      questionnaireFilter: $questionnaireFilter
      internalSurveyReportAccessToken: $internalSurveyReportAccessToken
      internalSurveyUrlHash: $internalSurveyUrlHash
    ) {
      average
      countOfIndividualAnswers {
        questionLabel
        value
      }
    }
  }
`;

export const createProfileCompanyCultureDataQueryVariables = (profileId: number, query: ParsedUrlQuery) => {
  return {
    profileId,
    questionnaireFilter: createQuestionnaireFilterInput(query),
    ...createInternalSurveyReportQueryVariables(query),
  };
};

export const PROFILE_RECOMMENDATION_DATA = gql`
  query RecommendationData(
    $profileId: Int!
    $questionnaireFilter: QuestionnaireFilterInput
    $internalSurveyReportAccessToken: String
    $internalSurveyUrlHash: String
  ) {
    recommendationData(
      profileId: $profileId
      questionnaireFilter: $questionnaireFilter
      internalSurveyReportAccessToken: $internalSurveyReportAccessToken
      internalSurveyUrlHash: $internalSurveyUrlHash
    ) {
      suitableFor {
        questionnaireLabel
      }
      numberOfEmployeesRecommending
      lastElaborationText
      elaborationsCount
    }
  }
`;

export const createRecommendationDataQueryVariables = (profileId: number, query: ParsedUrlQuery) => {
  return {
    profileId,
    questionnaireFilter: createQuestionnaireFilterInput(query),
    ...createInternalSurveyReportQueryVariables(query),
  };
};

export const PROFILE_SUITABLE_CARDS = gql`
  query SuitableForTiles($isForHomepage: Boolean!) {
    suitableForTiles(isForHomepage: $isForHomepage) {
      label
      imageUrl
      companyListLink
      countOfResults
      tileSize
    }
  }
`;

export const INTRODUCTION_SUITABLE_FOR_TILE = gql`
  query SuitableForTileForIntroductionPage {
    suitableForTileForIntroductionPage {
      label
      imageUrl
      companyListLink
      countOfResults
    }
  }
`;

export const PROFILE_SUITABLE_FOR_ELABORATIONS_MODAL = gql`
  query SuitableForElaborationsModal($profileId: Int!, $suitableForOptionLabel: String!) {
    suitableForElaborationsModal(profileId: $profileId, suitableForOptionLabel: $suitableForOptionLabel) {
      fieldOfWorkLabel
      isCurrentlyEmployed
      elaborationText
    }
  }
`;

export const PROFILE_FOR_FORMER_COMPANY_GROUP = gql`
  query ProfileForFormerCompanyGroup($companyGroupId: Int!) {
    profileForFormerCompanyGroup(companyGroupId: $companyGroupId) {
      id
      webalizedName
    }
  }
`;

export const PROFILE_FOR_MERGED_COMPANY = gql`
  query ProfileForMergedCompany($formerCompanyId: Int!) {
    profileForMergedCompany(formerCompanyId: $formerCompanyId) {
      id
      webalizedName
    }
  }
`;

export const INTERESTING_PROFILE_GALLERY_ITEMS = gql`
  query InterestingProfileGalleryItems($promotedProfilesCount: Int!) {
    interestingProfileGalleryItems(promotedProfilesCount: $promotedProfilesCount) {
      profileCardsData {
        ...ProfileCardDataFragment
      }
    }
  }
  ${PROFILE_CARD_DATA_FRAGMENT}
`;

export const PROFILE_OVERVIEW_FOR_JOB_DESCRIPTION = gql`
  query ProfileOverviewForJobDescription($companyG2Id: Int!) {
    profileOverviewForJobDescription(companyG2Id: $companyG2Id) {
      ...ProfileOverviewFragment
    }
  }
  ${PROFILE_OVERVIEW_FRAGMENT}
`;
